<template>
  <div class="min-h-screen bg-green-500">
    <TnHeader />

    <div class="mx-auto min-h-screen max-w-7xl px-0 py-12 sm:px-6 lg:px-8">
      <slot />
    </div>

    <TnFooter />
  </div>
</template>
